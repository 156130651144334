// eslint-disable-next-line no-restricted-imports
import { parseCookies, destroyCookie, setCookie } from 'nookies';

const ONE_HOUR_IN_SECONDS = 60 * 60;

type CookieName = 'token' | 'Idempotency-Key' | 'queryParams';

const getCookieExpireTime = (lifetimeInSeconds: number) => {
  const expires = new Date();

  expires.setTime(expires.getTime() + lifetimeInSeconds * 1000);

  return expires;
};

export const getClientCookie = (name: CookieName) => parseCookies(null)[name];

export const setClientCookie = (
  name: CookieName,
  value: string,
  lifetimeInSeconds = ONE_HOUR_IN_SECONDS,
) => {
  setCookie(null, name, value, {
    path: '/',
    expires: getCookieExpireTime(lifetimeInSeconds),
  });
};

export const removeClientCookie = (name: CookieName) => {
  destroyCookie(null, name, {
    path: '/',
  });
};
