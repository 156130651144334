// Copied from https://github.com/dnozay/innersvg-polyfill/blob/master/innersvg.js

if (typeof window !== 'undefined') {
  (function () {
    var serializeXML = function (node, output) {
      const nodeType = node.nodeType;
      if (nodeType == 3) {
        // TEXT nodes.
        // Replace special XML characters with their entities.
        output.push(
          node.textContent
            .replace(/&/, '&amp;')
            .replace(/</, '&lt;')
            .replace('>', '&gt;'),
        );
      } else if (nodeType == 1) {
        // ELEMENT nodes.
        // Serialize Element nodes.
        output.push('<', node.tagName);
        if (node.hasAttributes()) {
          const attrMap = node.attributes;
          for (var i = 0, len = attrMap.length; i < len; ++i) {
            const attrNode = attrMap.item(i);
            output.push(' ', attrNode.name, "='", attrNode.value, "'");
          }
        }
        if (node.hasChildNodes()) {
          output.push('>');
          const childNodes = node.childNodes;
          for (var i = 0, len = childNodes.length; i < len; ++i) {
            serializeXML(childNodes.item(i), output);
          }
          output.push('</', node.tagName, '>');
        } else {
          output.push('/>');
        }
      } else if (nodeType == 8) {
        // TODO(codedread): Replace special characters with XML entities?
        output.push('<!--', node.nodeValue, '-->');
      } else {
        // TODO: Handle CDATA nodes.
        // TODO: Handle ENTITY nodes.
        // TODO: Handle DOCUMENT nodes.
        throw 'Error serializing XML. Unhandled node of type: ' + nodeType;
      }
    };
    // The innerHTML DOM property for SVGElement.
    if (!('innerHTML' in SVGElement.prototype)) {
      Object.defineProperty(SVGElement.prototype, 'innerHTML', {
        get: function () {
          const output = [];
          let childNode = this.firstChild;
          while (childNode) {
            serializeXML(childNode, output);
            childNode = childNode.nextSibling;
          }
          return output.join('');
        },
        set: function (markupText) {
          // Wipe out the current contents of the element.
          while (this.firstChild) {
            this.removeChild(this.firstChild);
          }

          try {
            // Parse the markup into valid nodes.
            const dXML = new DOMParser();
            dXML.async = false;
            // Wrap the markup into a SVG node to ensure parsing works.
            const sXML =
              "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>" +
              markupText +
              '</svg>';
            const svgDocElement = dXML.parseFromString(
              sXML,
              'text/xml',
            ).documentElement;

            // Now take each node, import it and append to this element.
            let childNode = svgDocElement.firstChild;
            while (childNode) {
              this.appendChild(this.ownerDocument.importNode(childNode, true));
              childNode = childNode.nextSibling;
            }
          } catch (e) {
            throw new Error('Error parsing XML string');
          }
        },
      });
    }

    // The innerSVG DOM property for SVGElement.
    if (!('innerSVG' in SVGElement.prototype)) {
      Object.defineProperty(SVGElement.prototype, 'innerSVG', {
        get: function () {
          return this.innerHTML;
        },
        set: function (markupText) {
          this.innerHTML = markupText;
        },
      });
    }
  })();
}
