// eslint-disable-next-line no-restricted-imports
import type { Configuration } from '@happykit/flags/config';

export type AppFlags = {
  'header-ma-voiture':
    | 'Entretenir ma voiture'
    | 'Entretenir votre voiture'
    | null;
  'header-mes-pneus': 'Changer mes pneus' | 'Changer vos pneus' | null;
  'CTA-moteur-voir-les-prix': 'Voir les prix ' | 'Devis gratuit ' | null;
  'Bloc-moteur-home-V2':
    | 'Version initiale moteur'
    | 'Version mosaïque moteur bordeaux'
    | 'Version mosaïque moteur noir'
    | null;
};

export const config: Configuration<AppFlags> = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  envKey: process.env.NEXT_PUBLIC_HAPPYKIT_FLAGS_KEY!,

  // You can provide defaults flag values here
  defaultFlags: {
    'header-ma-voiture': null,
    'header-mes-pneus': null,
    'CTA-moteur-voir-les-prix': null,
    'Bloc-moteur-home-V2': null,
  },
};
