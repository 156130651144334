// eslint-disable-next-line no-restricted-imports
import {
  createUseFlags,
  type InitialFlagState as GenericInitialFlagState,
} from '@happykit/flags/client';
import { createUseFlagBag, FlagBagProvider } from '@happykit/flags/context';
import type { FunctionComponent, ReactNode } from 'react';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { type AppFlags, config } from './config';

export type InitialFlagState = GenericInitialFlagState<AppFlags>;
const useFlagsBase = createUseFlags<AppFlags>(config);
const useFlagBagBase = createUseFlagBag<AppFlags>();

type FlagsProviderProps = {
  children: ReactNode;
};

export const FlagsProvider: FunctionComponent<FlagsProviderProps> = ({
  children,
}) => {
  const flags = useFlagsBase();

  return <FlagBagProvider value={flags}>{children}</FlagBagProvider>;
};

export const useFlag = <Name extends keyof AppFlags>(name: Name) => {
  const { flags } = useFlagBagBase();

  const flag = (flags?.[name] ?? null) as AppFlags[Name];

  useEffect(() => {
    if (flag === undefined) {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: 'AB test',
        eventAction: 'affichage',
        nomTest: name,
        eventLabel: flag,
      },
    });
  }, [flag, name]);

  return [
    flag,
    () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'eventGA',
          eventCategory: 'AB test',
          eventAction: 'clic',
          nomTest: name,
          eventLabel: flag,
        },
      });
    },
  ] as const;
};
